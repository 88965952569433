import React, { useRef, useEffect } from 'react';

function useScrollAnimate() {
  const elementsRef = useRef(null);
  const windowHeightRef = useRef(null);

  function scrollAnimationInit() {
    elementsRef.current = document.querySelectorAll('.scroll-animatable');
    windowHeightRef.current = window.innerHeight;
  }

  function scrollAnimationCheckPosition() {
    for (let i = 0; i < elementsRef.current.length; i++) {
      let element = elementsRef.current[i];
      let positionFromTop = elementsRef.current[i].getBoundingClientRect().top;

      if (positionFromTop - windowHeightRef.current <= 0) {
        element.classList.add('scroll-animate');
        element.classList.remove('scroll-animatable');
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollAnimationCheckPosition);
    window.addEventListener('resize', scrollAnimationInit);

    scrollAnimationInit();
    scrollAnimationCheckPosition();
  }, []);
}

const ScrollAnimate = ({ children }) => (
  <div className="scroll-animatable">{children}</div>
);

export { ScrollAnimate };

export default useScrollAnimate;
