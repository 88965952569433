import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';

const HeroImage = ({ image, backdrop, children, className, marginTop }) => {
  return (
    <HeroImageWrapper
      backdrop={backdrop}
      className={className}
      marginTop={marginTop}
    >
      <Fragment>
        {image}
        <div className="hero-image__content">{children}</div>
      </Fragment>
    </HeroImageWrapper>
  );
};

const HeroImageWrapper = Styled.div`
  position: relative;
  max-height: 500px;
  overflow: hidden;
  /* margin-top: 80px; */
  margin-top: ${props => (props.marginTop ? props.marginTop : '50px')};

  .hero-image__content {
    color: #FFF;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-transform: uppercase;
  }

  &:after {
    content: '';
    position: absolute;
    display: ${({ backdrop }) => (backdrop === true ? 'block' : 'none')};
    z-index: 1;
    background-color: rgba(0, 0, 0, .35);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

HeroImage.defaultProps = {
  fullscreen: false,
  backdrop: true,
};

HeroImage.propTypes = {
  // The content to display over the hero image.
  children: PropTypes.node,
  // The URL of the image.  // This needs to be rendered with gatsby-image.  This approach
  // might not work.
  image: PropTypes.any.isRequired,
  // Whether or not the hero image should attempt to be sized as full screen.
  fullscreen: PropTypes.bool,
};

export default HeroImage;
