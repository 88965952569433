import React, { Fragment, useState } from 'react';

import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { MdStar } from 'react-icons/md';

import { ThemeProvider } from 'styled-components';

import Layout from '../layouts/mainLayout';
import HeroImage from '../components/heroImage';
import CalloutBlock from '../components/calloutBlock';
import TestimonialBlock from '../components/testimonialBlock';
import ContactForm from '../components/contactForm';
import AnnouncementBanner from '../components/announcementBanner';
import useScrollAnimate from '../components/scrollAnimate/ScrollAnimate';
import { ScrollAnimate } from '../components/scrollAnimate/ScrollAnimate';
import Button from '../components/common/elements/button/MaterialButton';

import config from '../config';

import {
  HeroImageContent,
  HeroButton,
  HomePageWrapper,
  ServicesSectionWrapper,
  IntroductionSectionWrapper,
  TestimonialSectionWrapper,
  ThankYouLetterWrapper,
  ContactSectionWrapper,
} from '../styles/pages/homepage';

import theme from '../styles/theme';

export const query = graphql`
  query homePageQueries {
    hero: file(relativePath: { regex: "/deluxe-clean-hero-image/" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    house: file(relativePath: { regex: "/house.png/" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rug: file(relativePath: { regex: "/rug.png/" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cat: file(relativePath: { regex: "/cat.png/" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sofa: file(relativePath: { regex: "/sofa.png/" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const hero = data => {
  return (
    <HeroImage
      marginTop="80px"
      image={
        <Img
          fluid={data.hero.childImageSharp.fluid}
          alt="Carpet cleaning before and after with our deluxe package."
        />
      }
    >
      <HeroImageContent>
        <h1>Sci-Tech Carpet Cleaning</h1>
        <h2>
          Call Us: <a href={config.phoneNumberHref}>{config.phoneNumber}</a>
        </h2>
        <HeroButton
          href="/contact"
          bgcolor="hsl(348, 100%, 46%)"
          bghovercolor="hsl(348, 100%, 51%)"
        >
          Contact Us
        </HeroButton>
        <HeroButton
          href="/why-choose-us"
          bgcolor="hsl(141, 71%, 33%)"
          bghovercolor="hsl(141, 71%, 38%)"
        >
          Why Choose Us
        </HeroButton>
      </HeroImageContent>
    </HeroImage>
  );
};

const IndexPage = ({ data, location }) => {
  const structuredData = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'HomeAndConstructionBusiness',
    name: 'Sci-Tech Carpet Cleaning',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '740 Radford St.',
      addressLocality: 'Christiansburg',
      addressRegion: 'VA',
      postalCode: '24073',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.8',
      ratingCount: '16',
      bestRating: '5',
      worstRating: '4',
    },
    image: 'https://www.scitechcarpetcleaning.com/logo.png',
    email: 'scitechcarpets@gmail.com',
    telePhone: '540-381-7847',
    url: 'https://www.scitechcarpetcleaning.com',
    paymentAccepted: ['cash', 'check', 'credit card'],
    openingHours: 'Mo,Tu,We,Th,Fr 09:00-17:00',
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '09:00',
        closes: '17:00',
      },
    ],
    geo: {
      '@type': 'GeoCoordinates',
      latitude: '37.131067',
      longitude: '-80.375842',
    },
    priceRange: '$$',
  });

  useScrollAnimate();

  return (
    <ThemeProvider theme={theme}>
      <Layout hero={hero(data)}>
        <Fragment>
          <Helmet defer={false}>
            <title>Sci-Tech Carpet Cleaning | Get Clean Carpets Today!</title>
            <meta
              name="description"
              content="Click here to find out how Sci-Tech can make your carpets cleaner than ever.  Nobody beats our customer service and quality of work."
            />
            <link rel="canonical" href="https://scitechcarpetcleaning.com" />
            <script type="application/ld+json">{structuredData}</script>
          </Helmet>
          <HomePageWrapper>
            <ServicesSection data={data} />
            <div style={{ marginTop: '48px' }} />
            <AnnouncementBanner>
              <p>
                We now offer{' '}
                <strong>
                  <Link to="/air-duct-cleaning">
                    air duct cleaning services
                  </Link>
                </strong>
                . We're also pleased to announce that our prices are set to try
                to beat our competitors'. Call us at{' '}
                <a href={config.phoneNumberHref}>{config.phoneNumber}</a> for a
                free consultation.
              </p>
            </AnnouncementBanner>
            <IntroductionSection />
            <TestimonialSection />
            <ThankYouLetter />
            <ContactSection />
          </HomePageWrapper>
        </Fragment>
      </Layout>
    </ThemeProvider>
  );
};

const ServicesSection = ({ data }) => {
  return (
    <ServicesSectionWrapper>
      <div style={{ textAlign: 'center' }}>
        <h2 style={{ textTransform: 'uppercase' }}>Services</h2>
        <p style={{ marginBottom: '50px' }}>
          We are the premier provider of many services in Blacksburg, Roanoke,
          and the rest of the New River Valley of Virginia.
        </p>
      </div>
      <div className="services-grid">
        <ScrollAnimate>
          <CalloutBlock
            icon={
              <Img
                fixed={data.house.childImageSharp.fixed}
                alt="House icon representing carpet cleaning."
              />
            }
            color="#E7675A"
            title="Carpet Cleaning"
            link="/carpet-cleaning"
          >
            <p>
              We provide the best carpet cleaning experience in the area. We use
              only the best cleaning materials available, and our technicians
              are experienced and well-trained.
            </p>
          </CalloutBlock>
        </ScrollAnimate>
        <ScrollAnimate>
          <CalloutBlock
            icon={
              <Img
                fixed={data.rug.childImageSharp.fixed}
                alt="Rug icon representing rug cleaning."
              />
            }
            color="#3ED195"
            title="Area Rug Cleaning"
            link="/rug-cleaning"
          >
            <p>
              We have a state-of-the-art rug cleaning facility in
              Christiansburg, VA. From our location, we provide a safe and
              thorough multi-step cleaning process to leave your rugs looking
              better than ever!
            </p>
          </CalloutBlock>
        </ScrollAnimate>
        <ScrollAnimate>
          <CalloutBlock
            icon={
              <Img
                fixed={data.cat.childImageSharp.fixed}
                alt="Cat icon representing pet odor removal."
              />
            }
            color="#41B1E0"
            title="Pet Odor Removal"
            link="/pet-odor-removal"
          >
            <p>
              Pet odor problems? We can fix that. Using our powerful
              enzyme-based odor removal products, we can treat many pet odor
              issues.
            </p>
          </CalloutBlock>
        </ScrollAnimate>
        <ScrollAnimate>
          <CalloutBlock
            icon={
              <Img
                fixed={data.sofa.childImageSharp.fixed}
                alt="Sofa icon representing upholstery cleaning."
              />
            }
            color="#F6D76A"
            title="Upholstery Cleaning"
            link="/upholstery-cleaning"
          >
            <p>
              Whether a fabric or leather, we can clean and condition your
              furniture to remove soils, odorants, and many different types of
              spots and stains.
            </p>
          </CalloutBlock>
        </ScrollAnimate>
      </div>
    </ServicesSectionWrapper>
  );
};

const IntroductionSection = () => {
  return (
    <IntroductionSectionWrapper>
      <div className="introduction-section__container">
        <div className="introduction-section__inner-container">
          <h2 style={{ textTransform: 'uppercase' }}>
            Sci-Tech Carpet Cleaning
          </h2>
          <p style={{ textAlign: 'center' }} className="text-center">
            Serving Blacksburg, Roanoke, and the rest of the New River Valley
            for over 20 years
          </p>
          <hr />
          <ScrollAnimate>
            <h3>
              We are your premier full-service floor care and home service
              specialist
            </h3>
            <p>
              Whether you need carpet cleaning, painting, air duct cleaning, or
              any other sort of cleaning, we have it covered.
            </p>
            <p>
              We provide quality service and stand by our belief that we can't
              be beaten in quality, value, and professionalism. Unlike many
              other service professionals that you may have dealt with, we go
              the extra mile to ensure that you receive the best service
              possible.
            </p>
            <p>
              We also offer extremely competitive prices that are hard to beat.
              There really is no better value around.
            </p>
            <p>
              We serve the areas of Blacksburg, Christiansburg, Roanoke, and
              surrounding areas.
            </p>
            <p>
              Call us at{' '}
              <a href={config.phoneNumberHref}>{config.phoneNumber}</a> or{' '}
              <Link to="/contact">send us an email</Link>.
            </p>
          </ScrollAnimate>
        </div>
      </div>
    </IntroductionSectionWrapper>
  );
};

const TestimonialSection = () => {
  const [showAll, setShowAll] = useState(false);
  return (
    <TestimonialSectionWrapper>
      <div className="testimonial-section__container">
        <div className="testimonial-section__inner-container">
          <ScrollAnimate>
            <TestimonialBlock name="Jim">
              <p>
                Immediate response for Emergency water remediation as a result
                of water lines bursting in neighboring townhouse unit. First
                class service… as always, Sci-Tech has provided this same
                service for me for as long as I can remember, (10-15) years! You
                have cleaned hundreds of units for me, and as always, top notch
                service! Thanks, guys, and you can rest assure, I recommend you
                to everyone that has a need for your service!
              </p>
              <p>
                <MdStar />
                <MdStar />
                <MdStar />
                <MdStar />
                <MdStar />
              </p>
            </TestimonialBlock>
            <TestimonialBlock name="Margaret R.">
              <p>
                Sci Tech does by far the best carpet cleaning, I’ve ever had.
                They clean my furniture, will even take furniture to their place
                of business to make sure it’s what I would expect. Their rates
                are very good, excellent customer service. On time, they don’t
                waste any time.
              </p>
              <p>
                <MdStar />
                <MdStar />
                <MdStar />
                <MdStar />
                <MdStar />
              </p>
            </TestimonialBlock>
            <TestimonialBlock name="Andrea M.">
              <p>
                I was very pleased with the company’s professionalism and
                quality of work. They provided a cost estimate prior to the
                start of the work. There was a spot that did not come out
                completely and they were here the next day to fix it at no
                additional charge.
              </p>
              <p>
                <MdStar />
                <MdStar />
                <MdStar />
                <MdStar />
                <MdStar />
              </p>
            </TestimonialBlock>
            <TestimonialBlock name="Diane Z.">
              <p>
                Courteous, professional, attentive to details. Have used them
                more than once and always find the work top quality.
              </p>
              <p>
                <MdStar />
                <MdStar />
                <MdStar />
                <MdStar />
                <MdStar />
              </p>
            </TestimonialBlock>
            <TestimonialBlock name="Tina M.">
              <p>
                I met Michael from Sci-Tech at the NRV Home and Garden Show. It
                was a Saturday evening and I asked him if he could get my
                carpets cleaned on Monday as I had company coming in from out of
                town and had completely forgotten to schedule a carpet cleaning.
                He confirmed all of my information right there, gave me a quote
                and promised they would call me Monday to do my cleaning. Well,
                he actually called on SUNDAY to confirm and then the driver
                called again Monday morning to let me know he was on his way.
                The guys who arrived at my home were polite and professional.
                They walked through the house with me both before and after the
                cleaning to make sure I was informed and satisfied with the
                service. To top it off, Monday afternoon, I received another
                call from Michael asking if I was pleased with the service. I
                will definitely continue to use them for all of my carpet
                cleaning.
              </p>
              <p>
                <MdStar />
                <MdStar />
                <MdStar />
                <MdStar />
                <MdStar />
              </p>
            </TestimonialBlock>
            <TestimonialBlock name="Anonymous">
              <p>
                Our rugs were clean and fresh. For our allergies and asthma
                that’s important. There was no chemical smell. Thank you.
              </p>
              <p>
                <MdStar />
                <MdStar />
                <MdStar />
                <MdStar />
                <MdStar />
              </p>
            </TestimonialBlock>
            {!showAll && (
              <Button
                style={{
                  display: 'block',
                  margin: '12px auto',
                  backgroundColor: 'hsl(17,87%,56%)',
                }}
                onClick={() => setShowAll(true)}
              >
                See more
              </Button>
            )}
            {showAll && (
              <div>
                <TestimonialBlock name="Mary B.">
                  <p>
                    We had our townhouse carpets cleaned when we put it on the
                    market and they looked great! I also had my oriental rug
                    cleaned to put in my living room in my new house and I'm
                    very happy with the outcome! The rug looks new again! All of
                    the colors are brighter and it looks great! Will definitely
                    use Sci-Tech Carpet Cleaning again!
                  </p>
                  <p>
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStar />
                  </p>
                </TestimonialBlock>
                <TestimonialBlock name="Dylan B.">
                  <p>
                    I called relatively late (7:30pm) and was expecting to have
                    to call back the next day, but a friendly gentleman answered
                    and scheduled my appointment. Employee showed up on time,
                    and worked quickly and thuroughly. I was emailed my receipt
                    immediately just so I could provide it to my complex when
                    turning in my key right after they were done. Carpets looked
                    and smelled great, and weren't overly soaked. Good price as
                    well. I'll be recommending to others :)
                  </p>
                  <p>
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStar />
                  </p>
                </TestimonialBlock>
                <TestimonialBlock name="Joanne A.">
                  <p>
                    Professional, knowledgeable, state-of-the-art equipment,
                    fair prices, friendly folks .......... these guys and gals
                    got it all right! They do my home rugs and carpets as well
                    as rental property. They wouldn't "come home" if they
                    weren't great!
                  </p>
                  <p>
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStar />
                  </p>
                </TestimonialBlock>
                <TestimonialBlock name="Cindy B.">
                  <p>
                    Thank you so much for making our carpet look great again!
                    One of your competitors told us the stains would not come
                    out---I believed them. (We have two dogs, two cats, and two
                    kids so things can be rough!) Apparently not true as our
                    carpets are beautiful after the Sci Tech royal treatment! A
                    friend remarked the carpet looks "like new." I am beyond
                    thrilled!
                  </p>
                  <p>
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStar />
                  </p>
                </TestimonialBlock>
              </div>
            )}
          </ScrollAnimate>
        </div>
      </div>
    </TestimonialSectionWrapper>
  );
};

const ThankYouLetter = () => {
  return (
    <ThankYouLetterWrapper>
      <ScrollAnimate>
        <div>
          <h2 style={{ marginBottom: '48px' }}>THANK YOU</h2>
          <p>
            <strong>Dear friend,</strong>
          </p>
          <p>
            Thank you for taking the time out of your day to visit our website
            and consider us for your cleaning needs.
          </p>
          <p>
            We have been serving The New River Valley and Roanoke areas for{' '}
            <strong>more than twenty years</strong> now. Every day, we are
            touched and grateful that so many in our community continue to trust
            us as their service provider.
          </p>
          <p>
            We want to provide you with the{' '}
            <strong>best service experience</strong> possible.
          </p>
          <p>
            Because of this, I ask you to{' '}
            <strong>
              let us know if there is anything that we can do to better assist
              you
            </strong>
            . If there is anything at all that doesn’t exceed your hopes or
            expectations, please reach out and tell us.
          </p>
          <p>
            There is a contact form on this page that you can fill out to send
            us an email. Or if you prefer, you can give us a call.
          </p>
          <p>Thank you again for visiting our website today.</p>
          <p>
            <span>Michael & Rob</span>
            <br />
            <span style={{ fontSize: '.85em' }}>
              <em>Co-owners of Sci-Tech Carpet Cleaning</em>
            </span>
          </p>
        </div>
      </ScrollAnimate>
    </ThankYouLetterWrapper>
  );
};

const ContactSection = () => {
  return (
    <ContactSectionWrapper>
      <ScrollAnimate>
        <ContactForm />
      </ScrollAnimate>
    </ContactSectionWrapper>
  );
};

export default IndexPage;
