import Styled from 'styled-components';
import { fullWidthMixin } from '../mixins';

const HeroImageContent = Styled.div`
  width: 100%;
  text-align: center;

  h1 {
    font-size: 50px;
  }

  h2 {
    margin-bottom: 33.5px;

    a {
      color: #CB4332;
      color: ${({ theme }) => theme.primary.colors.red.lightest};
      margin-left: 18px;
      display: inline-block;
      text-decoration: none;
      position: relative;
      transition: all .3s ease;

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        display: block;
        width: 100%;
        height: 3px;
        background-color: ${({ theme }) => theme.primary.colors.red.lightest};
        opacity: 0;
        transition: all .3s ease;
      }

      &:hover {
        color: ${({ theme }) => theme.primary.colors.red.lightest};
      }

      &:hover:after {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 800px) {
    h1 {
      font-size: 36px;
    }
  }

  @media screen and (max-width: 600px) {
    h1 {
      font-size: 30px;
    }

    h2 {
      font-size: 24px;
      margin-bottom: 24px;
    }
  }

  @media screen and (max-width: 520px) {
    h1 {
      font-size: 22px;
    }

    h2 {
      font-size: 18px;
      margin-bottom: 18px;

      a {
        font-size: 22px;
      }
    }
  }

  @media screen and (max-width: 340px) {
    h1 {
      font-size: 18px;
    }

    h2 {
      font-size: 16px;
      margin-bottom: 12px;

      a {
        font-size: 18px;
      }
    }
  }
`;

const HeroButton = Styled.a`
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 20px;
  padding: 10px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #FFF;
  background-color: ${props => props.bgcolor};
  border: none;
  text-decoration: none;
  letter-spacing: 1.2px;
  transition: all .3s ease;

  &:hover {
    background-color: ${props => props.bghovercolor}
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }

  @media screen and (max-width: 460px) {
    padding: 6px 9px;
  }

  @media screen and (max-width: 340px) {
    font-size: 12px;
  }
`;

const HomePageWrapper = Styled.div`
  p, h1, h2, h3, h4, h5, h6, span {
    color: #333;
  }

  p {
    font-size: 20px;
    line-height: 135%;
  }
`;

const ServicesSectionWrapper = Styled.section`
  box-sizing: border-box;
  ${fullWidthMixin};
  padding-left: 48px;
  padding-right: 48px;
  
  .services-grid {
    display: flex;
    justify-content: space-evenly;
  }

  @media screen and (max-width: 700px) {
    .services-grid {
      flex-direction: column;
    }
  }
`;

const IntroductionSectionWrapper = Styled.section`
  /* margin-top: 70px; */

  .introduction-section__container {
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: #272727;
    background-color: rgb(250, 250, 250);
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    h2 {
      color: white;
      color: #333;
      text-align: center;
    }

    h3 {
      color: white;
      color: #333;
      font-size: 40px;
      text-align: center;
      text-transform: uppercase;
    }

    p {
      color: white;
      color: #333;
    }

    a {
      color: #C84332;
      color: ${({ theme }) => theme.primary.colors.red.darker};
      text-decoration: none;
      position: relative;
      transition: all .3s ease;

      &:after {
        content: '';
        display: block;
        height: 3px;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: ${({ theme }) => theme.primary.colors.red.darker};
        opacity: 0;
        transition: all .3s ease;
      }

      &:hover:after {
        /* text-decoration: underline; */
        opacity: 1;
      }
    }

    .introduction-section__inner-container {
      box-sizing: border-box;
      padding: 0 24px;
      max-width: 720px;
      margin: 0 auto;
    }
  }
`;

const TestimonialSectionWrapper = Styled.section`
  .testimonial-section__container {
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: #41B1E0;
    background-color: ${({ theme }) => theme.primary.colors.lightBlue.lightest};
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  .testimonial-section__inner-container {
    box-sizing: border-box;
    padding: 0 24px;
    max-width: 720px;
    margin: 0 auto;
  }
`;

const ThankYouLetterWrapper = Styled.section`
  box-sizing: border-box;
  background-color: rgba(200, 200, 200, .05);
  padding: 24px 48px;
  margin-top: 48px;

  h2 {
    text-align: center;
  }

  @media screen and (max-width: 800px) {
    ${fullWidthMixin};
    padding: 24px;
  }
`;

const ContactSectionWrapper = Styled.section`
  text-align: center;
  margin-top: 70px;
`;

export {
  HeroImageContent,
  HeroButton,
  HomePageWrapper,
  ServicesSectionWrapper,
  IntroductionSectionWrapper,
  TestimonialSectionWrapper,
  ThankYouLetterWrapper,
  ContactSectionWrapper,
};
