import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Styled from 'styled-components';

const CalloutBlock = ({ icon, color, title, link, children }) => {
  return (
    <CalloutBlockWrapper color={color}>
      <Link to={link}>
        <div className="callout-block__icon">{icon}</div>
        <div className="callout-block__title">
          <h4>{title}</h4>
        </div>
      </Link>
      <div className="callout-block__content">{children}</div>
    </CalloutBlockWrapper>
  );
};

CalloutBlock.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  children: PropTypes.node,
};

const CalloutBlockWrapper = Styled.div`
  text-align: center;
  padding: 0 15px;

  a {
    text-decoration: none;
  }

  .callout-block__icon {
    border: 10px solid #ECECEC;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    transition: border-color .5s ease;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border: 10px solid ${({ color }) => color};
    }
  }

  .callout-block__title {
    margin-bottom: 20px;
    text-transform: uppercase;

    &:after {
      display: block;
      content: "";
      border-bottom: 2px solid ${({ color }) => color};
      width: 50%;
      margin: 10px auto 0 auto;
    }
  }
`;

export default CalloutBlock;
