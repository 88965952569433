import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { growl } from '../redux/actions/notifications/actionCreators';

import { Formik, Form } from 'formik';
import axios from 'axios';

import Styled, { ThemeProvider } from 'styled-components';

import config from '../config';
import theme from '../styles/theme';

const GRECAPTCHA_KEY = '6Ldvw8MUAAAAAKrSwppK084WQufIAY0cz5f9_KKs';

function ContactForm({ growl, blackbox }) {
  function handleSubmit(values, { setSubmitting }) {
    if (blackbox) {
      values = Object.assign({}, values, { blackbox });
    }
    window.grecaptcha.ready(function() {
      window.grecaptcha
        .execute(GRECAPTCHA_KEY, { action: 'email' })
        .then(function(token) {
          values = Object.assign({}, values, { recaptcha: token });
          axios
            .post(`${config.backendServer}/email`, values)
            .then(res => {
              if (res.status === 200) {
                growl({
                  message: 'Message sent successfully.',
                  type: 'success',
                });
              } else if (res.status === 500) {
                growl({
                  message:
                    'There was a problem sending your message.  Please try again.',
                  type: 'danger',
                });
              }
            })
            .catch(err => {
              console.error(err);
            })
            .finally(() => {
              setSubmitting(false);
            });
        });
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${GRECAPTCHA_KEY}`}
        />
        <style type="text/css">{`.grecaptcha-badge { visibility: hidden; }`}</style>
      </Helmet>
      <ContactFormWrapper className="contact-form">
        <h2>Contact Us</h2>
        <h4>
          <a href={config.phoneNumberHref}>{config.phoneNumber}</a>
        </h4>
        <a
          className="contact-section__phone-number-button"
          href={config.phoneNumberHref}
        >
          Call Us
        </a>
        <hr />
        <p>Send Us An Email</p>
        <div className="contact-section__contact-form">
          <Formik
            initialValues={{
              name: '',
              email: '',
              message: '',
            }}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <label htmlFor="contact-form__name">Your name</label>
                <input
                  type="name"
                  name="name"
                  id="contact-form__name"
                  placeholder="John Smith"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="contact-form__email">Your email</label>
                <input
                  type="email"
                  name="email"
                  id="contact-form__email"
                  placeholder="john.smith@email.com"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="contact-form__message">Your message</label>
                <textarea
                  name="message"
                  id="contact-form__message"
                  placeholder="This is my message..."
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <button disabled={isSubmitting} type="submit">
                  Send Message
                </button>
                <p className="captcha-notice">
                  This site is protected by reCAPTCHA and the Google{' '}
                  <a href="https://policies.google.com/privacy">
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a href="https://policies.google.com/terms">
                    Terms of Service
                  </a>{' '}
                  apply.
                </p>
              </Form>
            )}
          </Formik>
        </div>
      </ContactFormWrapper>
    </ThemeProvider>
  );
}

const ContactFormWrapper = Styled.section`
  text-align: center;

  h2 {
    text-transform: uppercase;
    margin-bottom: 12px;
  }

  h4 {
    margin-bottom: 12px;
    margin-top: 12px;
  }

  a {
    text-decoration: none;
    color: #999;
  }

  .contact-section__phone-number-button {
    display: inline-block;
    padding: 6px 18px;
    background-color: #F0662F;
    color: white;
    border-radius: 3px;
    transition: all .3s ease;

    &:hover {
      background-color: #d35b32;
      text-decoration: none;
      color: white;
    }
  }

  hr {
    border: 1px solid rgba(200, 200, 200, .3) !important;
    margin: 20px 25% !important;
  }

  .contact-section__contact-form {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    margin: 0 auto;

    input, textarea, button {
      margin-bottom: 12px;
    }

    input {
      display: block;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }

    textarea {
      height: 120px;
      display: block;
      width: 100%;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      overflow: auto;
      box-sizing: border-box;
    }

    label {
      display: block;
      text-align: left;
      margin-bottom: 6px;
      color: #333;
    }

    button {
      padding: 10px 16px;
      font-size: 18px;
      line-height: 1.3333333;
      border-radius: 4px;
      color: #fff;
      background-color: #3676D9;
      background-color: ${({ theme }) => theme.primary.colors.darkBlue.base};
      border-color: #2e6da4;
      display: inline-block;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      cursor: pointer;
      user-select: none;
      background-image: none;
      border: 1px solid transparent;
      transition: all .3s ease;
      width: 100%;

      &:hover {
        background-color: #2f69bf;
        background-color: ${({ theme }) =>
          theme.primary.colors.darkBlue.lighter};
      }

      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    p.captcha-notice {
      font-size: 14px;

      a {
        font-size: 14px;
      }
    }
  }
`;

const mapDispatchToProps = dispatch => {
  return {
    growl: ({ type, message }) => dispatch(growl({ type, message })),
  };
};

export default connect(null, mapDispatchToProps)(ContactForm);
