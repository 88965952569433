import React from 'react';

import { fullWidthMixin } from '../styles/mixins';

import Styled from 'styled-components';

const AnnouncementBanner = ({ children }) => {
  return (
    <AnnouncementBannerWrapper className="announcement-banner">
      <h3>Announcement</h3>
      <div className="announcement-banner__content">{children}</div>
    </AnnouncementBannerWrapper>
  );
};

const AnnouncementBannerWrapper = Styled.div`
  ${fullWidthMixin}

  padding: 24px 25%;
  box-sizing: border-box;
  background-color: #FED7D7;
  color: black !important;
  border-top: 6px solid #E53E3E;
  border-bottom: 6px solid #E53E3E;

  @media screen and (max-width: 700px) {
    padding-right: 12px;
    padding-left: 12px;
  }

  h3 {
    margin-top: 0;
    color: #E53E3E;
    text-align: center;
    font-size: 24px;
  }

  .announcement-banner__content {
    color: inherit;
    text-align: center;

    & * {
      color: inherit;
    }

    a {
      color: #E53E3E;
      transition: all 0.3s ease;

      &:hover {
        color: #C53030;
      }
    }
  }
`;

export default AnnouncementBanner;
