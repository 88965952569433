import React from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';

const TestimonialBlock = ({ name, children }) => {
  return (
    <TestimonialBlockWrapper>
      <blockquote>
        {children}
        <footer>{name}</footer>
      </blockquote>
    </TestimonialBlockWrapper>
  );
};

TestimonialBlock.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
};

const TestimonialBlockWrapper = Styled.div`
  background-color: #FFF;
  padding: 2em 1em;
  border-radius: 5px;
  margin-bottom: 2em;

  blockquote {
    border-left: 5px solid black;
    border-color: #EEEEEE;
    padding-left: 20px;

    p {
      font-size: 16px;
    }
  
    footer {
      font-style: italic;
      font-size: 14px;
  
      &:before {
        content: '\\2014 ';
      }
    }
  }

  @media screen and (max-width: 500px) {
    blockquote {
      margin-left: 6px;
    }
  }
  
`;

export default TestimonialBlock;
